import { render, staticRenderFns } from "./bankaccount.vue?vue&type=template&id=70a70990&"
import script from "./bankaccount.vue?vue&type=script&lang=ts&"
export * from "./bankaccount.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports